import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './Home';
import Game from './Game';
import Preregistration from './Prepegistration';

export default function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Preregistration />} />
                <Route path="demo" element={<Home />} />
                <Route path="session/:userId" element={<Home />} />
                <Route path="prompt-version/:versionId" element={<Home />} />
                <Route path="game/:gameId" element={<Game />} />
            </Routes>
        </div>
    );
}
