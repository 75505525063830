// import { Button } from '@/components/ui/button';
import { Scroll, Sword } from 'lucide-react';

export default function AdventureLandingPage() {
    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-amber-100 text-center p-4 overflow-hidden relative">
            {/* Decorative elements */}
            <div className="absolute top-0 left-0 w-16 h-16 border-t-4 border-l-4 border-amber-700 rounded-tl-3xl"></div>
            <div className="absolute bottom-0 right-0 w-16 h-16 border-b-4 border-r-4 border-amber-700 rounded-br-3xl"></div>

            <main className="max-w-2xl mx-auto relative z-10">
                <Sword className="w-16 h-16 mx-auto mb-4 text-amber-700 animate-pulse" />
                <h1 className="text-5xl font-bold mb-4 text-amber-900 font-serif tracking-wide">
                    AI Quest Generator
                </h1>
                <p className="text-xl mb-8 text-amber-800">
                    Forge your destiny with AI-crafted adventures
                </p>

                <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
                    <a
                        href="https://play.google.com/store/apps/details?id=com.oneclickdev.storiented"
                        target="_blank"
                    >
                        <button className="w-full flex sm:w-auto px-6 py-3 text-lg bg-amber-700 hover:bg-amber-800 text-white border-2 border-amber-600 shadow-md transition-all hover:shadow-lg font-medium">
                            <Scroll className="w-6 h-6 mr-2" />
                            Embark on Google Play
                        </button>
                    </a>
                    <a href="/demo" passHref>
                        <button
                            variant="outline"
                            className="w-full sm:w-auto px-6 py-3 bg-amber-200 text-amber-900 border-2 border-amber-600 hover:bg-amber-300 transition-all font-medium"
                        >
                            Sample the Quest
                        </button>
                    </a>
                </div>
            </main>

            {/* Background decorative elements */}
            <div className="absolute top-1/4 left-10 text-6xl text-amber-200 opacity-20 transform -rotate-12">
                🗡️
            </div>
            <div className="absolute bottom-1/4 right-10 text-6xl text-amber-200 opacity-20 transform rotate-12">
                🛡️
            </div>
            <div className="absolute top-1/2 left-1/4 text-6xl text-amber-200 opacity-20 transform rotate-45">
                📜
            </div>
        </div>
    );
}
