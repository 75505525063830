import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ApiService } from './services/api-service';
import { cache } from './services/cache-service';
import ClipLoader from 'react-spinners/ClipLoader';
import * as localization from './services/localization';
import GameHistory from './components/gameHistory';

const apiService = new ApiService();

function Game() {
    const [userId, setUserId] = useState('');
    const navigate = useNavigate();
    const { gameId } = useParams();
    const { state } = useLocation();
    const initialized = useRef(false);

    const [sceneDescription, setSceneDescription] = useState('');
    const [historyId, setHistoryId] = useState('');

    const [userActions, setUserActions] = useState([]);

    const [locationKey, setLocationKey] = useState('');
    const [locationImageBaseUrl, setLocationImageBaseUrl] = useState('');
    const [locationImage, setLocationImage] = useState('');

    const [uiVisible, setUiVisible] = useState(true);
    const [historyVisible, setHistoryVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const [language, setLanguage] = useState('english');

    const [storyStartText, setStoryStartText] = useState(null);

    useEffect(() => {
        if (initialized.current) {
            return;
        }

        initialized.current = true;

        const userId = cache.get('userId');
        if (!userId) {
            navigate('/');
        }
        setUserId(userId);

        const _language = cache.get('language');
        setLanguage(_language);

        const baseImgUrl = cache.get('locationImageContainerUrl');
        setLocationImageBaseUrl(baseImgUrl);

        const game = null; // state?.game; // disable this buggy
        if (game) {
            setSceneDescription(game.scene.text);
            setHistoryId(game.scene.historyId);
            setUserActions(game.scene.actions);
            if (game.scene.location) {
                loadImage(
                    baseImgUrl,
                    game.scene.location.key,
                    game.scene.location.filename
                );
            }
            if (game.status === 'started') {
                setStoryStartText(game.story.prehistory);
            }
        } else {
            fetchGame();
        }

        async function fetchGame() {
            setLoading(true);
            const game = await apiService.getGame({ userId, gameId });
            setLoading(false);
            setHistoryId(game.scene.historyId);
            setSceneDescription(game.scene.text);
            setUserActions(game.scene.actions);
            if (game.scene.location) {
                loadImage(
                    baseImgUrl,
                    game.scene.location.key,
                    game.scene.location.filename
                );
            }
            if (game.status === 'started') {
                setStoryStartText(game.story.prehistory);
            }
        }
    }, []);

    async function executeAction(action) {
        if (loading) return;

        if (action.text === 'End') {
            navigate('/');
            return;
        }

        setLoading(true);
        const nextScene = await apiService.executeAction({
            userId,
            gameId,
            actionId: action.id,
        });
        setLoading(false);

        setHistoryId(nextScene.scene.historyId);
        setSceneDescription(nextScene.scene.text);
        setUserActions(nextScene.scene.actions);
        if (
            nextScene.scene.location &&
            nextScene.scene.location.key !== locationKey
        ) {
            loadImage(
                locationImageBaseUrl,
                nextScene.scene.location.key,
                nextScene.scene.location.filename
            );
        }
    }

    async function loadImage(baseImgUrl, key, filename) {
        if (!baseImgUrl) return;
        setLocationKey(key);
        const fullUrl = new URL(baseImgUrl);
        fullUrl.pathname += '/' + filename;
        setLoading(true);
        const res = await check();
        if (res) {
            setLocationImage(fullUrl.href);
        }
        setLoading(false);

        async function check(tries = 0) {
            if (!window.location.href.includes(`game/${gameId}`)) return false;
            if (tries > 20) return false;
            setLoading(true);

            try {
                const response = await fetch(fullUrl);
                if (response.ok) {
                    return true;
                }
            } catch (err) {
                console.log(err);
            }

            await new Promise((res) => setTimeout(res, 1000 + tries * 300));
            return check(tries + 1);
        }
    }

    function toggleUi() {
        setUiVisible(!uiVisible);
    }

    function showHistory() {
        setHistoryVisible(true);
    }

    return (
        <div>
            <ClipLoader
                color={'#36d7b7'}
                loading={loading}
                size={100}
                cssOverride={{
                    zIndex: 100,
                    margin: 'auto',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    position: 'absolute',
                }}
                aria-label="Loading Spinner"
                data-testid="loader"
            />

            {historyVisible && (
                <GameHistory
                    gameId={gameId}
                    userId={userId}
                    cursor={historyId}
                    open={historyVisible}
                    setOpen={setHistoryVisible}
                    language={language}
                ></GameHistory>
            )}

            <div
                className="flex flex-col h-lvh bg-cover bg-center bg-no-repeat bg-surfaceVariant"
                style={{
                    backgroundImage: `url(${locationImage})`,
                }}
            >
                <div className="flex h-full flex-col">
                    <div
                        className={'flex-1 ' + (!uiVisible ? 'invisible' : '')}
                    >
                        <div className="flex flex-col place-items-center">
                            <div>
                                <h1 className="m-3 p-4 text-justify text-md sm:text-lg md:text-3xl bg-surface bg-opacity-50 text-onSurface rounded-xl">
                                    {storyStartText ?? sceneDescription}
                                </h1>
                            </div>
                            {storyStartText && (
                                <>
                                    <button
                                        onClick={() => setStoryStartText(null)}
                                        className="mb-3 bg-primary hover:bg-outline text-onPrimary font-bold py-2 px-4 rounded-full"
                                    >
                                        {localization.beginAdventure[language]}
                                    </button>
                                </>
                            )}
                            {!storyStartText && (
                                <div className="flex flex-col">
                                    {userActions.map((action, index) => (
                                        <button
                                            key={action.id + historyId}
                                            onClick={() =>
                                                executeAction(action)
                                            }
                                            className="mb-3 bg-primary hover:bg-outline text-onPrimary font-bold py-2 px-4 rounded-full"
                                        >
                                            {action.text}
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex">
                        <div className="group relative">
                            <button
                                type="button"
                                onClick={() => toggleUi()}
                                className="m-3 bg-secondary hover:bg-outline text-onSecondary font-bold py-2 px-4 rounded-full"
                            >
                                {!uiVisible && (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="size-6"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                                        />
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                        />
                                    </svg>
                                )}
                                {uiVisible && (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="size-6"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                                        />
                                    </svg>
                                )}
                            </button>
                            <div class=" absolute bottom-20 left-0 right-0 scale-0 rounded bg-background p-2 text-xs text-onBackground group-hover:scale-100">
                                {localization.toggleUi[language]}
                            </div>
                        </div>
                        <div className="group relative">
                            <button
                                type="button"
                                onClick={() => showHistory()}
                                className="m-3 bg-secondary hover:bg-outline text-onSecondary font-bold py-2 px-4 rounded-full"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="size-6"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                    />
                                </svg>
                            </button>
                            <div class=" absolute bottom-20 left-0 right-0 scale-0 rounded bg-background p-2 text-xs text-onBackground group-hover:scale-100">
                                {localization.history[language]}
                            </div>
                        </div>
                        {/* <div className="flex-1"></div> */}
                        <div className="group relative">
                            <button
                                type="button"
                                onClick={() => navigate('/')}
                                className="m-3 bg-secondary hover:bg-outline text-onSecondary font-bold py-2 px-4 rounded-full"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="size-6"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                                    />
                                </svg>
                            </button>
                            <div class=" absolute bottom-20 left-0 right-0 scale-0 rounded bg-background p-2 text-xs text-onBackground group-hover:scale-100">
                                {localization.backToGamesList[language]}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Game;
